<template>
  <el-container class="shopList">
    <el-header class="box bb add aic">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>项目管理</el-breadcrumb-item>
        <el-breadcrumb-item>项目列表</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-main class="p15 pt0">
      <div class="pt20">
        <el-form :inline="true" :model="page" class="demo-form-inline">
          <el-form-item>
            <el-input
              placeholder="项目名"
              v-model="page.keyword"
              clearable
              @clear="search"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              type="primary"
              @click="search"
              size="medium"
              style="margin-right: 10px"
              >查询</el-button
            >
            <el-popover
              placement="bottom"
              width="340"
              trigger="click"
              v-model="showAdd"
            >
              <div style="padding: 10px">
                <el-form
                  inline
                  :model="newProjcet"
                  :rules="rules"
                  ref="addForm"
                  label-width="0px"
                >
                  <el-form-item prop="projectName">
                    <el-input
                      v-model="newProjcet.projectName"
                      placeholder="项目名称"
                    ></el-input>
                  </el-form-item>

                  <el-form-item>
                    <el-button type="success" @click="addProject"
                      >确定</el-button
                    >
                  </el-form-item>
                </el-form>
              </div>
              <el-button
                slot="reference"
                icon="el-icon-plus"
                size="medium"
                type="success"
                @click="search"
                >新增</el-button
              >
            </el-popover>
          </el-form-item>
        </el-form>
      </div>
      <!-- <el-row class="box search mt20">
        <el-form :inline="true" :model="page" class="demo-form-inline">
          <el-col :span="4" class="mr10">
            <el-form-item size="medium">
              <el-input
                placeholder="项目名"
                v-model="page.keyword"
                clearable
                class="input-with-select"
                @clear="search"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="1" class="tl mr10">
            <el-form-item size="medium">
              <div class="df">
                <el-button icon="el-icon-search" type="primary" @click="search"
                  >查询</el-button
                >
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="1" class="tl mr10">
            <el-form-item size="medium">
              <div class="df">
                <el-popover
                  placement="bottom"
                  width="340"
                  trigger="click"
                  v-model="showAdd"
                >
                  <div style="padding: 10px">
                    <el-form
                      inline
                      :model="newProjcet"
                      :rules="rules"
                      ref="addForm"
                      label-width="0px"
                    >
                      <el-form-item label="" prop="projectName">
                        <el-input
                          v-model="newProjcet.projectName"
                          placeholder="项目名称"
                        ></el-input>
                      </el-form-item>

                      <el-form-item label="">
                        <el-button type="success" @click="addProject"
                          >确定</el-button
                        >
                      </el-form-item>
                    </el-form>
                  </div>
                  <el-button
                    slot="reference"
                    icon="el-icon-plus"
                    type="primary"
                    @click="search"
                    >新增</el-button
                  >
                </el-popover>
              </div>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row> -->
      <el-table
        :data="list"
        style="width: 100%"
        :header-cell-style="{
          background: '#eef1f6',
          color: '#606266',
        }"
      >
        <el-table-column type="index" align="center" label="序号" width="60" />
        <el-table-column
          prop="projectName"
          label="项目名称"
          width="180"
          align="center"
        >
          <template slot-scope="scope">
            <div v-if="!scope.row.isEdit">{{ scope.row.projectName }}</div>
            <div v-else>
              <el-form
                :model="scope.row"
                :rules="rules"
                ref="ruleForm"
                label-width="0px"
              >
                <el-form-item label="" prop="projectName">
                  <el-input v-model="scope.row.projectName"></el-input>
                </el-form-item>
              </el-form>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="createdTime"
          label="创建时间"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column label="操作" align="center" width="180">
          <template slot-scope="scope">
            <el-button
              type="text"
              class="disib blue"
              size="mini"
              @click="handleDetail(scope.row)"
              >进入项目</el-button
            >
            <el-button
              type="text"
              class="disib blue"
              size="mini"
              v-if="!scope.row.isEdit"
              @click="handleEdit(scope.row)"
              >修改</el-button
            >
            <el-button
              type="text"
              class="disib green"
              size="mini"
              v-else
              @click="handleEdit(scope.row)"
              >完成</el-button
            >
            <el-button
              type="text"
              class="disib red"
              size="mini"
              v-if="!scope.row.isEdit"
              @click="handleDel(scope.row)"
              >删除</el-button
            >
            <el-button
              type="text"
              class="disib red"
              size="mini"
              v-else
              @click="
                scope.row.isEdit = false;
                scope.row.projectName = oldData;
                oldData = null;
              "
              >取消</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="i-page disib mt20 df" style="justify-content: flex-end">
        <el-pagination
          background
          :page-size="10"
          layout="total,prev, pager, next"
          :total="total"
          :current-page="page.page"
          @current-change="pageChange"
        >
        </el-pagination>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import api from "@/util/extra-api";
import { local } from "@/util/util";

export default {
  data() {
    return {
      page: {
        page: 1,
        limit: 10,
        keyword: "",
      },
      list: [],
      detailList: [],
      total: 0,
      companyId: null,
      showAdd: false,
      newProjcet: {
        projectName: "",
      },
      detailTotal: 0,
      dialogTitle: "",
      //   dialogVisible: false,
      loading: false,
      oldData: null,
      rules: {
        projectName: [
          { required: true, message: "请输入项目名称", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.companyId = Number(local.get("company_id"));
    this.getList();
  },
  methods: {
    getList() {
      api
        .get(`/v1/pc/oa/project/list?companyId=${this.companyId}`, this.page)
        .then((res) => {
          this.list = res.data.map((v) => {
            v.isEdit = false;
            return v;
          });
          this.total = res.total;
        });
    },
    pageChange(e) {
      this.page.page = e;
      this.getList();
    },
    search() {
      this.page.page = 1;
      this.getList();
    },
    addProject() {
      this.$refs["addForm"].validate((valid) => {
        if (valid) {
          api
            .post(`/v1/pc/oa/project/add?companyId=${this.companyId}`, {
              projectName: this.newProjcet.projectName,
            })
            .then((res) => {
              if (res.code === 200) {
                this.getList();
                this.showAdd = false;
                this.newProjcet.projectName = "";
              }
            });
        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    },
    handleEdit(row) {
      if (!row.isEdit) {
        this.list.forEach((v) => {
          if (v.isEdit) {
            v.projectName = this.oldData;
          }
          v.isEdit = v === row;
          this.oldData = row.projectName;
        });
        // row.isEdit = true;
      } else {
        this.$refs["ruleForm"].validate((valid) => {
          if (valid) {
            api
              .post(`/v1/pc/oa/project/update?companyId=${this.companyId}`, {
                projectName: row.projectName,
                id: row.id,
              })
              .then((res) => {
                if (res.code === 200) {
                  this.getList();
                  this.oldData = null;
                }
              })
              .catch((err) => {
                this.getList();
              });
          } else {
            // console.log('error submit!!');
            return false;
          }
        });
      }
    },
    handleDel(row) {
      api
        .post(`/v1/pc/oa/project/remove?companyId=${this.companyId}`, {
          id: row.id,
        })
        .then((res) => {
          if (res.code === 200) {
            this.getList();
          }
        });
    },
    handleDetail(row) {
      this.$router.push({
        path: "/admin/task-list",
        query: {
          id: row.id,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.green {
  color: #67c23a;
}
</style>